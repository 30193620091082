<template>
    <div>
        <b-card>
            <template #header>
                <b-card-title>Account Trustee</b-card-title>
                <feather-icon icon="EditIcon" size="18" class="cursor-pointer" v-b-modal.accountTrusteeDetails-modal />
            </template>

            <b-card-body>
                <b-card-text>In the event of death, whom should entitle to claim this account</b-card-text>
                <h6 class="text-capitalize mb-75">
                    Trustee Name
                </h6>
                <b-card-text>
                    {{ trustee.trusteeName || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Trustee IC Number
                </h6>
                <b-card-text>
                    {{ trustee.trusteeIC || "(empty)" }}
                </b-card-text>
            </b-card-body>
        </b-card>

        <b-modal id="accountTrusteeDetails-modal" title="Marketers Banking Details" ok-title="Save" cancel-title="Discard" @ok="updateTrustee" centered>
            <b-row>
                <b-col>
                    <b-form-group label="Trustee Name" label-for="trustee-name">
                        <b-form-input v-model="input.trusteeName" placeholder="Trustee Name" name="trustee-name" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Trustee IC Number" label-for="trustee-ic">
                        <b-form-input v-model="input.trusteeIC" placeholder="Trustee IC Number" name="trustee-ic" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BModal, BFormGroup, BFormInput, BCol, BRow } from 'bootstrap-vue';
import { getProfile, updateProfile } from '@/services/AgentService';

export default {
    name: "AccountTrusteeDetails",
    components: {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BModal,
        BFormGroup,
        BFormInput,
        BCol,
        BRow
    },
    data() {
        return {
            trustee: {
                trusteeName: "",
                trusteeIC: ""
            },
            input: {
                trusteeName: "",
                trusteeIC: ""
            }
        }
    },
    async mounted() {
        const trustee = await getProfile();
        this.trustee = {
            trusteeName: trustee?.trusteeName ?? "",
            trusteeIC: trustee?.trusteeIC ?? ""
        };
        this.input = { ...this.trustee };
    },
    methods: {
        async updateTrustee() {
            await updateProfile(this.input);
            this.trustee = { ...this.input };
        }
    }

}
</script>

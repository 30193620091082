<template>
    <div>
        <b-card>
            <template #header>
                <b-card-title>Marketer's Details</b-card-title>
                <feather-icon icon="EditIcon" size="18" class="cursor-pointer" v-b-modal.marketersDetails-modal />
            </template>

            <b-card-body>
                <h6 class="text-capitalize mb-75">
                    Name
                </h6>
                <b-card-text>
                    {{ marketers.name || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    IC Number
                </h6>
                <b-card-text>
                    {{ marketers.ic || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Gender
                </h6>
                <b-card-text>
                    {{ marketers.gender || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Email
                </h6>
                <b-card-text>
                    {{ marketers.email || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Permanent Address
                </h6>
                <b-card-text>
                    <p style="page-break-after: always">{{ formattedAddress || "(empty)" }}</p>
                </b-card-text>
            </b-card-body>
        </b-card>

        <b-modal id="marketersDetails-modal" title="Marketers Details" ok-title="Save" cancel-title="Discard" @ok="updateMarketers" centered>
            <b-row>
                <b-col>
                    <b-form-group label="Name" label-for="name">
                        <b-form-input v-model="input.name" placeholder="Name" name="name" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="IC Number" label-for="ic">
                        <b-form-input v-model="input.ic" placeholder="IC Number" name="ic" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Gender" label-for="gender">
                        <b-form-input v-model="input.gender" placeholder="Gender" name="gender" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Email" label-for="email">
                        <b-form-input v-model="input.email" placeholder="Email" name="email" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Line 1" label-for="line1">
                        <b-form-input v-model="input.address.line1" placeholder="Line 1" name="line1" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Line 2" label-for="line2">
                        <b-form-input v-model="input.address.line2" placeholder="Line 2" name="line2" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Postcode" label-for="postcode">
                        <b-form-input v-model="input.address.postcode" placeholder="Postcode" name="postcode" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="State" label-for="state">
                        <b-form-input v-model="input.address.state" placeholder="State" name="state" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BModal, BFormGroup, BFormInput, BCol, BRow } from 'bootstrap-vue'
import { getProfile, updateProfile } from '@/services/AgentService';

export default {
    name: "MarketersDetails",
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCardText,
        BCardTitle,
        BModal,
        BFormGroup,
        BFormInput,
        BRow,
        BCol
    },
    data() {
        return {
            marketers: {
                name: "",
                ic: "",
                gender: "",
                phoneNo: "",
                email: "",
                address: {
                    line1: "",
                    line2: "",
                    postcode: "",
                    state: ""
                }
            },
            input: {
                name: "",
                ic: "",
                gender: "",
                phoneNo: "",
                email: "",
                address: {
                    line1: "",
                    line2: "",
                    postcode: "",
                    state: ""
                }
            }
        }
    },
    computed: {
        formattedAddress() {
            if (this.marketers.address === undefined) return "";
            const { line1, line2, postcode, state } = this.marketers.address;
            if (line1 === "" || line2 === "" || postcode === "" || state === "") return "";

            return `
            ${line1},
            ${line2},
            ${postcode},${state}
            `
        }
    },
    async mounted() {
        const marketers = await getProfile();
        this.marketers = {
            name: marketers?.name ?? "",
            ic: marketers?.ic ?? "",
            gender: marketers?.gender ?? "",
            phoneNo: marketers?.phoneNo ?? "",
            email: marketers?.email ?? "",
            address: {
                line1: marketers?.address?.line1 ?? "",
                line2: marketers?.address?.line2 ?? "",
                postcode: marketers?.address?.postcode ?? "",
                state: marketers?.address?.state ?? "",
            }
        };
        this.input = { ...this.marketers };
    },
    methods: {
        async updateMarketers() {
            await updateProfile(this.input);
            this.marketers = { ...this.input };
        }
    }
}
</script>
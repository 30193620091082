<template>
    <div>
        <b-card>
            <template #header>
                <b-card-title>Social Media</b-card-title>
                <feather-icon icon="EditIcon" size="18" class="cursor-pointer" v-b-modal.socialMediaDetails-modal />
            </template>
            <b-card-body>
                <h6 class="text-capitalize mb-75">
                    Twitter
                </h6>
                <b-card-text>
                    {{ social.twitter || "(empty)" }}
                </b-card-text>
                <h6 class="text-capitalize mb-75">
                    Instagram
                </h6>
                <b-card-text>
                    {{ social.instagram || "(empty)" }}
                </b-card-text>
                <h6 class="text-capitalize mb-75">
                    Facebook
                </h6>
                <b-card-text>
                    {{ social.facebook || "(empty)" }}
                </b-card-text>
                <h6 class="text-capitalize mb-75">
                    Youtube
                </h6>
                <b-card-text>
                    {{ social.youtube|| "(empty)" }}
                </b-card-text>
                <h6 class="text-capitalize mb-75">
                    Tiktok
                </h6>
                <b-card-text>
                    {{ social.tiktok || "(empty)" }}
                </b-card-text>
                <h6 class="text-capitalize mb-75">
                    Whatsapp
                </h6>
                <b-card-text>
                    {{ social.whatsapp || "(empty)" }}
                </b-card-text>
            </b-card-body>
        </b-card>

        <b-modal id="socialMediaDetails-modal" title="Marketers Details" ok-title="Save" cancel-title="Discard" @ok="updateSocial" centered>
            <b-row>
                <b-col>
                    <b-form-group label="Twitter" label-for="twitter">
                        <b-form-input v-model="input.twitter" placeholder="Twitter" name="twitter" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Instagram" label-for="instagram">
                        <b-form-input v-model="input.instagram" placeholder="Instagram" name="instagram" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Facebook" label-for="facebook">
                        <b-form-input v-model="input.facebook" placeholder="Facebook" name="facebook" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Youtube" label-for="youtube">
                        <b-form-input v-model="input.youtube" placeholder="Youtube" name="youtube" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Tiktok" label-for="tiktok">
                        <b-form-input v-model="input.tiktok" placeholder="Tiktok" name="tiktok" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Whatsapp" label-for="whatsapp">
                        <b-form-input v-model="input.whatsapp" placeholder="Whatsapp" name="whatsapp" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BModal, BFormGroup, BFormInput, BCol, BRow } from 'bootstrap-vue'
import { getProfile, updateProfile } from '@/services/AgentService';

export default {
    name: "SocialMediaDetails",
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCardText,
        BCardTitle,
        BModal,
        BFormGroup,
        BFormInput,
        BRow,
        BCol
    },
    data() {
        return {
            social: {
                twitter: "",
                instagram: "",
                facebook: "",
                youtube: "",
                tiktok: "",
                whatsapp: ""
            },
            input: {
                twitter: "",
                instagram: "",
                facebook: "",
                youtube: "",
                tiktok: "",
                whatsapp: ""
            }
        }
    },
    async mounted() {
        const social = await getProfile();
        this.social = {
            twitter: social?.twitter ?? "",
            instagram: social?.instagram ?? "",
            facebook: social?.facebook ?? "",
            youtube: social?.youtube ?? "",
            tiktok: social?.tiktok ?? "",
            whatsapp: social?.whatsapp ?? ""
        };
        this.input = { ...this.marketers };
    },
    methods: {
        async updateSocial() {
            await updateProfile(this.input);
            this.social = { ...this.input };
        }
    }
}
</script>
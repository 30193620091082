<template>
    <div>
        <b-row>
            <b-col>
                <MarketersDetails />
                <SocialMediaDetails />
            </b-col>

            <b-col>
                <BankAccountDetails />
                <AccountTrusteeDetails />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import BankAccountDetails from './BankAccountDetails.vue';
import AccountTrusteeDetails from './AccountTrusteeDetails.vue';
import { BCol, BRow } from "bootstrap-vue";
import MarketersDetails from './MarketersDetails.vue';
import SocialMediaDetails from './SocialMediaDetails.vue';
export default {
    name: "Profile",
    components: { BCol, BRow, BankAccountDetails, AccountTrusteeDetails, MarketersDetails, SocialMediaDetails }
}
</script>
<template>
    <div>
        <b-card>
            <template #header>
                <b-card-title>Bank Account</b-card-title>
                <feather-icon icon="EditIcon" size="18" class="cursor-pointer" v-b-modal.bankAccount-modal />
            </template>

            <b-card-body>
                <h6 class="text-capitalize mb-75">
                    Bank Account Name
                </h6>
                <b-card-text>
                    {{ bank.accountName || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Bank Account Number
                </h6>
                <b-card-text>
                    {{ bank.accountNumber || "(empty)" }}
                </b-card-text>

                <h6 class="text-capitalize mb-75">
                    Bank Name
                </h6>
                <b-card-text>
                    {{ bank.bankName || "(empty)" }}
                </b-card-text>
            </b-card-body>
        </b-card>

        <b-modal id="bankAccount-modal" title="Marketers Banking Details" ok-title="Save" cancel-title="Discard" @ok="updateBank" centered>
            <b-row>
                <b-col>
                    <b-form-group label="Bank Account Name" label-for="bank-account-name">
                        <b-form-input v-model="input.accountName" placeholder="Bank Account Name" name="bank-account-name" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Bank Account Number" label-for="bank-account-number">
                        <b-form-input v-model="input.accountNumber" placeholder="Bank Account Number" name="bank-account-number" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group label="Bank Name" label-for="bank-name">
                        <b-form-input v-model="input.bankName" placeholder="Bank Name" name="bank-name" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BModal, BFormGroup, BFormInput, BCol, BRow } from 'bootstrap-vue'
import { getProfile, updateProfile } from '@/services/AgentService';
export default {
    name: "BankAccountDetails",
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCardText,
        BCardTitle,
        BModal,
        BFormGroup,
        BFormInput,
        BRow,
        BCol
    },
    data() {
        return {
            bank: {
                accountName: "",
                accountNumber: "",
                bankName: ""
            },
            input: {
                accountName: "",
                accountNumber: "",
                bankName: ""
            }
        }

    },
    async mounted() {
        const bank = await getProfile();
        this.bank = {
            bankName: bank?.bankName ?? "",
            accountNumber: bank?.accountNumber ?? "",
            accountName: bank?.accountName ?? ""
        };
        this.input = { ...this.bank };
    },
    methods: {
        async updateBank() {
            await updateProfile(this.input);
            this.bank = { ...this.input };
        }
    }
}
</script>